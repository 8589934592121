import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import {AppConstant} from "../app.constant";

const evalApi: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_API_URL || "",
    headers: {
        "Content-Type": "application/json",
    },
});

const surveyApi: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_API_URL_SURVEY || "",
    headers: {
        "Content-Type": "application/json",
    },
});

const fundingApi: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_API_URL_FUNDING || "",
    headers: {
        "Content-Type": "application/json",
    },
});

const surveyFundingApi: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_API_URL_SURVEY_FUNDING_PERCENT || "",
    headers: {
        "Content-Type": "application/json",
    },
});

const deleteApi: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_API_URL_DELETE || "",
    headers: {
        "Content-Type": "application/json",
    },
});

interface RetryConfig extends AxiosRequestConfig {
    retry: number;
    retryDelay: number;
}

const globalConfig: RetryConfig = {
    retry: 3,
    retryDelay: 1000,
};

evalApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config } = error;

        if (!config || !config.retry) {
            return Promise.reject(error);
        }
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log("retry the request", config.url);
                resolve();
            }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => evalApi(config));
    }
);

surveyApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config } = error;

        if (!config || !config.retry) {
            return Promise.reject(error);
        }
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log("retry the request", config.url);
                resolve();
            }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => surveyApi(config));
    }
);

fundingApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config } = error;

        if (!config || !config.retry) {
            return Promise.reject(error);
        }
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log("retry the request", config.url);
                resolve();
            }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => fundingApi(config));
    }
);

surveyFundingApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config } = error;

        if (!config || !config.retry) {
            return Promise.reject(error);
        }
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log("retry the request", config.url);
                resolve();
            }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => surveyFundingApi(config));
    }
);

deleteApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config } = error;

        if (!config || !config.retry) {
            return Promise.reject(error);
        }
        config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
            setTimeout(() => {
                console.log("retry the request", config.url);
                resolve();
            }, config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => deleteApi(config));
    }
);

export {evalApi,surveyApi,fundingApi,surveyFundingApi,deleteApi,globalConfig}

